import { ActionIcon, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';

import { DarkModeIcon, LightModeIcon } from '@apple/assets/icons';

export function ToggleColorSchemeButton() {
	const { setColorScheme } = useMantineColorScheme();
	const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

	return (
		<ActionIcon
			onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')}
			variant='transparent'
			c={'var(--mantine-color-bright)'}
			size='md'
			aria-label='Toggle color scheme'
		>
			{computedColorScheme === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
		</ActionIcon>
	);
}
