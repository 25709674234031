import { memo } from 'react';
import { Button, Popover, Select } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import type { ButtonProps } from '@mantine/core';

import { icons } from '@apple/assets';
import type { IconElement } from '@apple/assets/icons';

interface FilterBarDropdownProps extends ButtonProps {
	data: { value: string; label: string }[];
	onOptionSubmit: (value: string) => void;
	targetIcon: IconElement;
	placeholder?: string;
	buttonVariant?: 'transparent' | 'default';
	showRightSection?: boolean;
	isSearchable?: boolean;
	testId?: string;
}

export const FilterBarDropdown = memo<FilterBarDropdownProps>(
	({
		onOptionSubmit,
		data,
		targetIcon: TargetIcon,
		placeholder,
		buttonVariant,
		testId,
		showRightSection = true,
		isSearchable = true,
		...buttonProps
	}: FilterBarDropdownProps) => {
		const focusTrapRef = useFocusTrap();

		return (
			<Popover position='bottom-start' shadow='md'>
				<Popover.Target>
					<Button
						miw='15%'
						maw='15%'
						variant={buttonVariant}
						leftSection={<TargetIcon c='var(--mantine-primary-color-contrast)' />}
						rightSection={
							showRightSection && (
								<icons.ChevronDown c='var(--mantine-primary-color-contrast)' />
							)
						}
						data-testid={testId}
						{...buttonProps}
					/>
				</Popover.Target>
				<Popover.Dropdown>
					<Select
						ref={focusTrapRef}
						width='100%'
						placeholder={placeholder}
						dropdownOpened
						searchable={isSearchable}
						data={data}
						onChange={(value: string | null) => onOptionSubmit(value ?? '')}
					/>
				</Popover.Dropdown>
			</Popover>
		);
	},
);

FilterBarDropdown.displayName = 'FilterBarDropdown';
