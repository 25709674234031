import { useMemo } from 'react';
import {
	Button,
	Card,
	Center,
	Indicator,
	rem,
	ScrollAreaAutosize,
	Text,
	Timeline,
} from '@mantine/core';

import { AnnouncementIcon } from '@apple/assets/icons';
import { useAcknowledgeAnnouncement } from '@apple/features/announcements/hooks/useAcknowledgeAnnouncement';
import { useTranslation } from '@apple/lib/i18next';
import { MenuPopover } from '@apple/ui/shell/components/MenuPopover';
import { FormattedDate } from '@apple/utils/globalization/index';
import { useLogger } from '@apple/utils/logging/useLogger';

import { ActiveAnnouncements } from '../components/ActiveAnnouncements';
import { openAnnouncementModal } from '../components/AnnouncementModal';
import type { Announcement, AnnouncementList } from '../models/announcement';

type Props = {
	loading: boolean;
	values: AnnouncementList | null;
};

export function NotificationMenu({ loading, values }: Props) {
	const { t } = useTranslation('common');
	const acknowledgeAnnouncement = useAcknowledgeAnnouncement();

	const sortedAnnouncements = useMemo(() => {
		if (!values) {
			return {
				announcements: [],
				totalCount: 0,
				unreadCount: 0,
			} satisfies AnnouncementList;
		}

		return {
			...values,
			announcements: values.announcements.sort((a, b) => {
				if (a.active !== b.active) {
					return Number(b.active) - Number(a.active);
				}
				return b.createdDate.getTime() - a.createdDate.getTime();
			}),
		} satisfies AnnouncementList;
	}, [values]);

	function handleOpenAnnouncement(announcement: Announcement) {
		openAnnouncementModal({
			t,
			announcement,
			onAcknowledge: () => {
				acknowledgeAnnouncement.mutate(announcement.contentId);
			},
		});
	}

	// HACK: The backend doesn't provide the correct unread count, so we have to calculate it here
	// const notificationCount = values ? values.unreadCount : 0;
	const notificationCount =
		values?.announcements.reduce((count, announcement) => {
			return count + (announcement.acknowledged ? 0 : 1);
		}, 0) ?? 0;

	useLogger({
		name: 'NotificationMenu',
		props: [{ notificationCount, values, sortedAnnouncements }],
		log: 'announcement',
	});

	return (
		<>
			<ActiveAnnouncements announcementList={sortedAnnouncements} />
			<MenuPopover
				icon={<AnnouncementIcon size='80%' />}
				loading={loading}
				tooltipLabel={t('mainMenu.admin.content.announcements')}
				indicatorCount={notificationCount}
				indicatorDisabled={notificationCount <= 0}
				iconTestId='notification-icon'
				indicatorTestId='notification-badge'
			>
				<Card.Section
					m={0}
					p='md'
					style={{
						borderRadius: `${rem(24)} ${rem(24)} 0 0`,
						backgroundColor: 'var(--apple-color-card-header-bg)',
					}}
				>
					<Center>
						<Text fw='bold'>{t('mainMenu.admin.content.announcements')}</Text>
					</Center>
				</Card.Section>

				<Card.Section m='sm' mih={200} h='100%'>
					<ScrollAreaAutosize scrollbars='y' mah={500}>
						{sortedAnnouncements.announcements.length === 0 && (
							<Center
								mih={200}
								children={t('pagination.empty')}
								data-testid='empty-announcements-label'
							/>
						)}

						<Timeline
							active={sortedAnnouncements.announcements.findLastIndex(x => x.active)}
							bulletSize={32}
							lineWidth={2}
							styles={{
								item: {
									marginTop: rem(10),
								},
								itemBody: {
									paddingLeft: rem(6),
									paddingRight: rem(32),
								},
							}}
						>
							{sortedAnnouncements.announcements.map(announcement => (
								<Timeline.Item
									key={announcement.contentId}
									bullet={<AnnouncementIcon size='70%' />}
									c={announcement.active ? 'primary' : 'dimmed'}
									title={
										<Indicator
											inline
											size={9}
											position='middle-end'
											disabled={announcement.acknowledged}
											offset={-8}
										>
											<Button
												variant='subtle'
												p='xs'
												onClick={() => handleOpenAnnouncement(announcement)}
												c={announcement.acknowledged ? 'dimmed' : 'primary'}
												fw={announcement.active ? 'bold' : 'normal'}
											>
												{announcement.title}
											</Button>
										</Indicator>
									}
								>
									<FormattedDate
										size='xs'
										c='dimmed'
										value={announcement.createdDate}
										humanize
										pl={'sm'}
									/>
								</Timeline.Item>
							))}
						</Timeline>
					</ScrollAreaAutosize>
				</Card.Section>
			</MenuPopover>
		</>
	);
}
