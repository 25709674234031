import { ActionIcon, Card, Indicator, Popover, rem, Tooltip } from '@mantine/core';
import type { PopoverProps } from '@mantine/core';
import type { ReactNode } from 'react';

type MenuPopoverProps = {
	icon: React.ReactNode;
	loading?: boolean;
	tooltipLabel: string;
	indicatorCount?: number;
	indicatorDisabled?: boolean;
	children: ReactNode;
	popoverProps?: PopoverProps;
	iconTestId?: string;
	indicatorTestId?: string;
};

export function MenuPopover({
	icon,
	loading,
	tooltipLabel,
	indicatorCount,
	indicatorDisabled,
	children,
	popoverProps,
	iconTestId: iconTestId,
	indicatorTestId: indicatorTestId,
}: MenuPopoverProps) {
	return (
		<Popover
			position='bottom'
			withArrow
			arrowSize={16}
			arrowRadius={3}
			shadow='xl'
			transitionProps={{ transition: 'pop', duration: 150 }}
			zIndex={199}
			styles={{
				arrow: {
					backgroundColor: 'var(--apple-color-card-header-bg)',
				},
				dropdown: {
					minWidth: rem(300),
					maxHeight: rem(600),
					borderRadius: rem(24),
					padding: 0,
					margin: 0,
					borderColor: 'var(--mantine-color-default-border)',
					backgroundColor: `var(--apple-color-card-body-bg)`,
				},
			}}
			{...popoverProps}
		>
			<Popover.Target>
				<Tooltip label={tooltipLabel}>
					<Indicator
						data-testid={indicatorTestId}
						label={indicatorCount}
						disabled={indicatorDisabled}
						inline
					>
						<ActionIcon
							loading={loading}
							data-testid={iconTestId}
							variant='transparent'
							c={'var(--mantine-color-bright)'}
						>
							{icon}
						</ActionIcon>
					</Indicator>
				</Tooltip>
			</Popover.Target>
			<Popover.Dropdown>
				<Card
					variant='with-heading'
					p={0}
					styles={{
						root: {
							borderRadius: rem(24),
						},
					}}
				>
					{children}
				</Card>
			</Popover.Dropdown>
		</Popover>
	);
}
