import { Avatar, Group, Menu, rem, Stack, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRouter } from '@tanstack/react-router';
import { clsx as cx } from 'clsx';

import { icons } from '@apple/assets';
import { useAuthContext } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { Footnote } from '@apple/ui/typogrophy/components/footnote';
import { Headline } from '@apple/ui/typogrophy/components/headline';

import classes from './AvatarMenu.module.css';
import { ToggleColorSchemeMenuItem } from './ToggleColorSchemeMenuItem';

export function AvatarMenu({
	fullWidth = false,
	variant,
}: {
	fullWidth?: boolean;
	variant: 'light' | 'dark';
}) {
	const { t } = useTranslation('common');
	const auth = useAuthContext();
	const router = useRouter();
	const [open, openActions] = useDisclosure(false);
	const theme = useMantineTheme();

	if (!auth.profile) {
		return null;
	}

	const fullDisplayName = `${auth.profile.firstName} ${auth.profile.lastName}`;

	return (
		<Menu
			width={rem(260)}
			position='bottom-end'
			transitionProps={{ transition: 'pop-top-right' }}
			onClose={openActions.close}
			onOpen={openActions.open}
			closeOnEscape
			closeOnClickOutside
			withinPortal
		>
			<Menu.Target>
				<UnstyledButton
					w={fullWidth ? '100%' : undefined}
					p={rem(8)}
					className={cx(
						classes.user,
						open && variant === 'light' ? classes.activeLightBg : undefined,
						open && variant === 'dark' ? classes.activeDarkBg : undefined,
					)}
				>
					<Group gap={5} wrap='nowrap' justify='space-between'>
						<Group gap={5} wrap='nowrap'>
							<Avatar
								title={fullDisplayName}
								alt={fullDisplayName}
								radius='xl'
								color={variant === 'light' ? theme.black : theme.white}
								size={rem(42)}
							/>
							<Stack gap={0} justify='flex-start' align='flex-start'>
								<Headline bold>
									{auth.profile.firstName} {auth.profile.lastName}
								</Headline>
								<Footnote c='dimmed'>{auth.profile.roles}</Footnote>
							</Stack>
						</Group>
						<icons.ChevronDown size={24} />
					</Group>
				</UnstyledButton>
			</Menu.Target>

			<Menu.Dropdown className={classes.menuDropdown}>
				<Menu.Label>{t('shell.settings')}</Menu.Label>
				<Menu.Item
					onClick={() => void router.navigate({ to: '/profile' })}
					leftSection={<icons.ProfileEditIcon size={16} />}
				>
					{t('shell.accountSettings')}
				</Menu.Item>
				<ToggleColorSchemeMenuItem />
				<Menu.Divider />
				<Menu.Item
					onClick={() => void router.navigate({ to: '/logout' })}
					leftSection={<icons.Logout size={16} />}
				>
					{t('shell.logout')}
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
